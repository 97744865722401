<template>
	<div v-if="userData.id" class="wrap">
		<div class="top-bg"></div>
		<div class="content-top">
			<div class="head-img">
				<img :src="avatar" alt="头像" />
			</div>
			<div class="doctor-name"><span>{{userData.name}}</span><i /><span>{{userData.titleName}}</span></div>
			<div class="doctor-hospital"><span>{{userData.hospitalName}}</span></div>
		</div>
		<div class="content-center">
			<div class="center-top">
				<div class="center-top-item">
					<div>累计提现</div>
					<div>{{userData.cashoutTotalFee.split('.')[0]}}<span v-if="userData.cashoutTotalFee.split('.')[1]">.{{userData.cashoutTotalFee.split('.')[1]}}</span></div>
				</div>
				<div class="center-top-item">
					<div>当前可提现(税前)</div>
					<div>{{userData.cashoutEnable.split('.')[0]}}<span v-if="userData.cashoutEnable.split('.')[1]">.{{userData.cashoutEnable.split('.')[1]}}</span></div>
				</div>
			</div>
			<div class="center-button" :class="btnEnable ? 'enabled' : 'disabled'" @click="handleWithdraw">
				<span>去提现</span>
			</div>
		</div>
		<div class="content-third" @click="handleList">
			<i class="item-mark" />
			<span>收入及缴税详情</span>
			<i class="arrow" />
		</div>
		<div class="content-bottom">
			<div>*单日单笔提现上限为5000，下限为5</div>
			<div>*扣税比例如下，当月累计提现：</div>
			<div style="margin-left: 5px">800-5000元（累计提现金额-800）*20%</div>
			<div style="margin-left: 5px">5000-10000元（累计提现金额-5000）*10%</div>
			<div style="margin-left: 5px">1W-2W（累计提现金额-10000）*8%</div>
			<div style="margin-left: 5px">2W元以上（累计提现金额-10000）*6%</div>
		</div>
	</div>
</template>
<script>
import { doctorInfoInWechat } from '@/request/api/doctor-withdraw';
export default {
	data () {
		return {
			userData: {
				cashoutTotalFee: '0.00',
				cashoutEnable: '0.00'
			},
			btnEnable: false,
			avatar: require('../../assets/images/doctor-def.png')
		};
	},
	mounted () {
		this.getDoctorInfo();
	},
	methods: {
		getDoctorInfo () {
			const user = JSON.parse(window.sessionStorage.getItem('userInfo'));
			// console.log('user======', user)
			if (user.id) {
				doctorInfoInWechat({ userId: user.id }).then(data => {
					if (data && data === 'retry') {
						this.getDoctorInfo();
					} else if (data) {
						// console.log('data======', data);
						document.title = '提现';
						data.isDoctor = true;
						window.sessionStorage.setItem('doctorInfo', JSON.stringify(data));
						// const backUrl = window.localStorage.getItem('backUrl');
						// if (backUrl === '/doctor-withdraw') {
						// }
						window.localStorage.setItem('backUrl', '');
						this.userData = { ...user, ...data, cashoutTotalFee: data.cashoutTotalFee + '', cashoutEnable: data.cashoutEnable + '' };
						if (this.userData.avatar) this.avatar = this.userData.avatar;
						// console.log('this.userData=======', this.userData)
						this.btnEnable = data.cashoutEnable >= 5;
					}
				});
			} else {
				this.$root.replace('/complete-doctor');
			}
		},
		handleWithdraw () {
			if (+this.userData.cashoutEnable < 5) {

			} else {
				this.$root.go('/doctor-withdraw-apply');
			}
		},
		handleList () {
			this.$root.go('/doctor-withdraw-list');
		}
	}
};
</script>
<style lang="less" scoped>
	.wrap {
		.top-bg {
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			height: 239px;
			width: 100%;
			position: absolute;
			left: 0;
			z-index: -1;
		}
		.content-top {
			display: flex;
			flex-direction: column;
			align-items: center;
			.head-img {
				width: 70px;
				height: 70px;
				border-radius: 100%;
				overflow: hidden;
				margin-top: 20px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.doctor-name {
				font-size: 16px;
				color: #FFFFFF;
				line-height: 16px;
				margin-top: 12px;
				i {
					width: 1px;
					height: 10px;
					background: #FFFFFF;
					border-radius: 1px;
					opacity: 0.29;
				}
				span:first-child {
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					margin-right: 5px;
				}
				span:last-child {
					font-weight: 400;
					margin-left: 5px;
					font-family: PingFangSC-Regular, PingFang SC;
				}
			}
			.doctor-hospital {
				margin-top: 8px;
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 13px;
			}
		}
		.content-center {
			margin: 28px 0 0 0;
			background: #FFFFFF;
			box-shadow: 0px 5px 18px -4px rgba(16,31,46,0.04);
			border-radius: 4px;
			padding: 38px 20px 28px 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.center-top {
				display: flex;
				justify-content: space-around;
				.center-top-item {
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					div:first-child {
						font-size: 13px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #8B8B8B;
						line-height: 13px;
					}
					div:last-child {
						font-size: 22px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #F33D3A;
						line-height: 22px;
						margin-top: 19px;
						span {
							font-size: 13px;
						}
					}
				}
			}
			.enabled {
				opacity: 1;
			}
			.disabled {
				opacity: 0.5;
			}
			.center-button {
				background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				border-radius: 21px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 0;
				margin-top: 39px;
				span {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 16px;
				}
			}
		}
		.content-third {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #FFFFFF;
			box-shadow: 0px 5px 18px -4px rgba(16,31,46,0.04);
			border-radius: 4px;
			margin-top: 10px;
			padding: 18px 0;
			.item-mark {
				position: absolute;
				width: 4px;
				height: 13px;
				background-color: #2089FF;
			}
			span {
				font-size: 15px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #232830;
				line-height: 15px;
				margin-left: 12px;
			}
			.arrow {
				width: 8px;
				height: 14px;
				background: url(../../assets/images/right-arrow.png) no-repeat center center;
				background-size: cover;
				margin-right: 15px;
			}
		}
		.content-bottom {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #7D7D7D;
			line-height: 13px;
			margin-top: 10px;
			div {
				margin-top: 6px;
			}
		}
	}
</style>